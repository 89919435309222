<template>
  <div class="dashboard__container--body" v-if="(userProfile  && userProfile.id)">
    <div class="dashboard__container--body--col shiftCard">
      <ProfileRes :userProfile="userProfile" />
    </div>
  </div>
</template>


<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import ProfileRes from '@/components/Profile/ProfileRes.vue'

export default {
  name: 'accountResume',
  computed: {
    ...mapState(['userProfile', 'currentUser']),
  },
   components: {
    Loader,
    ProfileRes
  },
}
</script>