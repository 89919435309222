<template>
  <div class="whiteBack">
    <div class="mb-3">
      <!-- <label>Upload Your Resumé</label> -->
      <div class="flex justify-center">

      <input type="file" ref="file" id="fileUpload" accept="image/*,.pdf" @change="previewImage" hidden>
      <button @click="chooseFile()" class="btn btn__primary" v-if="!userProfile.picture">Upload File</button>
      <button @click="chooseFile()" class="btn btn__primary" v-if="userProfile.picture">Upload New File</button>
       <a :href="userProfile.picture" v-if="userProfile.picture" target="_blank">
        View Your Resumé <i class="fas fa-external-link ml-2"></i></a>
      </div>

     
      <!-- <input type="file" ref="file" accept="image/*,.pdf" @change="previewImage" class="mt-2"> -->

      <progress :value="uploadValue" max="100" v-if="showBar"></progress>

    </div>

</div>
	</div>
</template>

<script>
const fb = require('../../firebaseConfig.js')

export default {
  props: ['userProfile'],
  data: () => ({ 
    imageData: null,
    uploadValue: 0,
    showBar:false,
    picture: null,
    performingRequest: false,
    columns: [
      {
        label: 'Type',
        field: 'type',
        sortable: false
      },
      {
        label: 'Url',
        field: 'url',
        sortable: false
      },
      // {
      //   label: 'Delete',
      //   field: 'extras',
      //   sortable: false
      // },
    ]
  }),
  methods: {
    chooseFile() {
        document.getElementById("fileUpload").click()
    },
    previewImage(event) {
      this.uploadValue=0;
      this.picture=null
      this.imageData=event.target.files[0]
      this.onUpload()
    },
    onUpload() {
      this.showBar = true
      let userProfile = this.userProfile
      userProfile.picture=null
      let rand = (Math.random().toString(36).substring(2, 16) + Math.random().toString(36).substring(2, 16)).toUpperCase()
      let storageRef = fb.storageRef.child('docs/' + rand).put(this.imageData);
      storageRef.on(`state_changed`, snapshot => {
        this.uploadValue=(snapshot.bytesTransferred/snapshot.totalBytes)*100;
      }, error => {console.log(error.message)},
      () => {this.uploadValue=100;
        storageRef.snapshot.ref.getDownloadURL().then((url) => {
          userProfile.picture=url
          this.$store.dispatch('updateUser', userProfile)
        })
        this.showBar = false
      })
      this.imageData = null
      this.$refs.file.value=null
    },
    // deleteUploadedFile(u, index) {
    //   console.log(u)
    //   let userProfile = this.userProfile
    //   userProfile.certs.splice(index, 1)
    //   this.$store.dispatch('updateUser', userProfile)
    // },
    onFileTypeMismatch(file) {
      alert('Invalid file type. Please choose a jpeg, pdf, or png file.')
    },
    onFileSizeExceed(file) {
      alert('Please choose a file smaller than 2MB')
    },
  }
}
</script>